import {Weekdays} from './weekdays';
import {UserGroup} from 'src/modules/user-group/models/user-group';
import {Vehicle} from 'src/modules/vehicle/models/vehicle';
import {Provider} from 'src/modules/provider/models/provider';

export class Tariff {
  '@id': string;
  '@context': string;
  '@type': string;
  name: string;
  startDate: Date;
  endDate: Date;
  startTime: Date | string;
  endTime: Date | string;
  weekdays: Weekdays;
  calculatedEnd: Date;
  calculatedPrice: number;
  userGroups: string[] | UserGroup[];
  vehicles: string[] | Vehicle[];
  provider: string | Provider;
  id: string;
  price: number;

  constructor(tariff: Tariff) {
    this['@context'] = tariff['@context'];
    this['@id'] = tariff['@id'];
    this['@type'] = tariff['@type'];
    this.name = tariff.name;
    this.startDate = tariff.startDate;
    this.endDate = tariff.endDate;
    this.startTime = tariff.startTime;
    this.endTime = tariff.endTime;
    this.weekdays = tariff.weekdays;
    this.calculatedEnd = tariff.calculatedEnd;
    this.calculatedPrice= tariff.calculatedPrice;
    this.userGroups = tariff.userGroups;
    this.vehicles = tariff.vehicles;
    this.provider = tariff.provider;
    this.price = tariff.price;
    this.id = this['@id']?.split('/').pop();
  }
}
