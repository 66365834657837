import {Injectable} from '@angular/core';
import {ApiService} from '../../app/services/api.service';
import {forkJoin, Observable, of} from 'rxjs';
import {HydraSchema} from '@ui/models/hydra-schema';
import {HttpParams} from '@angular/common/http';
import {Lock} from 'src/modules/locks/models/lock';
import {TableService} from '@ui-tables/services/table.service';
import {catchError, map, switchMap, take} from 'rxjs/operators';
import {ProviderService} from 'src/modules/provider/services/provider.service';
import {Provider} from 'src/modules/provider/models/provider';

@Injectable({
  providedIn: 'root',
})
export class LockService {

  constructor(
    private apiService: ApiService,
    private tableService: TableService,
    private providerService: ProviderService
  ) {
  }

  getBikeLocksByProviderId(providerId: string): Observable<[HydraSchema<Lock>, HydraSchema<Lock>, HydraSchema<Lock>]> {
    const params = new HttpParams().set('provider', providerId.split('/').pop());
    const fetchPiBikeLocks = this.apiService.get<HydraSchema<Lock>>('/api/pi_bike_locks', {params});
    const fetchPiEasyLocks = this.apiService.get<HydraSchema<Lock>>('/api/pi_easy_locks', {params});
    const linkaLocks = this.apiService.get<HydraSchema<Lock>>('/api/linka_locks', {params});
    return forkJoin([fetchPiBikeLocks, fetchPiEasyLocks, linkaLocks]);
  }

  getAllLinkaLocksByPage(pages: number, filterParams?: HttpParams): Observable<HydraSchema<Lock>> {
    return this.tableService.getSingleTablePage('/api/linka_locks', pages, 10, filterParams).pipe(
      take(1),
      map((locks: HydraSchema<Lock>) => {
        locks['hydra:member'] = locks['hydra:member'].map((lock: Lock) => new Lock(lock));
        return locks;
      })
    );
  }

  fetchLinkaLockById(lockId: string): Observable<Lock> {
    return this.apiService.get<Lock>(`/api/linka_locks/${lockId}`).pipe(
      take(1),
      switchMap((lock: Lock) => this.fetchProviderDetails(lock)),
    );
  }


  addNewLock(lockData: Partial<Lock>): Observable<Lock> {
    return this.apiService.post<Lock>('/api/linka_locks', lockData).pipe(
      take(1),
      map((newlock: Lock) => new Lock(newlock))
    );
  }

  updateLockData(lockId: string, changedLockData: Partial<Lock>): Observable<Lock> {
    return this.apiService.patch<Lock>(`/api/linka_locks/${lockId}`, changedLockData).pipe(
      take(1),
      switchMap((lock: Lock) => this.fetchProviderDetails(lock))
    );
  }

  private fetchProviderDetails(lock: Lock): Observable<Lock> {
    console.log(lock.provider);
    if(!lock.provider) {
      return of(new Lock(lock));
    }
    const providerId = (lock.provider as string).split('/').pop();
    return this.providerService.fetchProvider(providerId).pipe(
      take(1),
      map((provider: Provider) => {
        lock.provider = provider;
        return new Lock(lock);
      }),
      catchError(_ =>  of(new Lock(lock)))
    );
  }
}
