import {HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {TableService} from '@ui-tables/services/table.service';
import {HydraSchema} from '@ui/models/hydra-schema';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {ApiService} from 'src/modules/app/services/api.service';
import {Booking} from '../models/booking';

@Injectable({
  providedIn: 'root'
})
export class BookingService {
  constructor(
    private tableService: TableService,
    private apiService: ApiService,
  ) {
  }

  getAllBookigsByPage(page?: number, filterParams = new HttpParams()): Observable<HydraSchema<Booking>> {
    filterParams = filterParams
      .set('groups[]', 'provider')
      .append('groups[]', 'vehicle')
      .append('groups[]', 'box')
      .append('groups[]', 'customer')
      .append('groups[]', 'user')
      .append('groups[]', 'sanction')
      .append('groups[]', 'payment')
      .append('groups[]', 'userGroup')
      .append('order[orderedStartTime]', 'desc');

    return this.tableService.getSingleTablePage<HydraSchema<Booking>>('/api/bookings', page, 10, filterParams).pipe(
      map(bookings => ({
        ...bookings,
        'hydra:member': bookings['hydra:member'].map(booking => new Booking(booking))
      }))
    );
  }

  fetchBooking(bookingId: string): Observable<Booking> {
    const params = new HttpParams()
      .set('groups[]', 'provider')
      .append('groups[]', 'vehicle')
      .append('groups[]', 'box')
      .append('groups[]', 'customer')
      .append('groups[]', 'user')
      .append('groups[]', 'sanction')
      .append('groups[]', 'payment')
      .append('groups[]', 'tariff')
      .append('groups[]', 'userGroup');
    return this.apiService.get<Booking>(`/api/bookings/${bookingId}`, {params});
  }

  deleteBooking(bookingId: string): Observable<Booking> {
    return this.apiService.delete(`/api/bookings/${bookingId}`);
  }

  cancelBooking(bookingId: string): Observable<Booking> {
    return this.apiService.patch<Booking>(`/api/bookings/${bookingId}`, {
      cancel: true
    });
  }

  endBooking(bookingId: string): Observable<Booking> {
    return this.apiService.patch<Booking>(`/api/bookings/${bookingId}`, {
      return: true
    });
  }

  fetchBookingsWithUserId(userId: string, page: number): Observable<HydraSchema<Booking>> {
    const params = new HttpParams()
      .set('customer', `${userId}`)
      .append('order[orderedStartTime]', 'desc')
      .append('orderedStartTime[strictlyAfter]', new Date().toDateString())
      .append('groups[]', 'provider')
      .append('groups[]', 'box')
      .append('groups[]', 'vehicle');
    return this.tableService.getSingleTablePage<HydraSchema<Booking>>('/api/bookings', page, 5, params)
      .pipe(
        map(bookings => {
          bookings['hydra:member'] = bookings['hydra:member'].map(booking => new Booking(booking));
          return bookings;
        }));
  }
}
