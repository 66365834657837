import {HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {SelectItem} from '@ui-forms/models/select-item';
import {TableService} from '@ui-tables/services/table.service';
import {HydraSchema} from '@ui/models/hydra-schema';
import {IsEmptyPipe} from '@ui/pipes/is-empty.pipe';
import {BehaviorSubject, combineLatest, forkJoin, Observable} from 'rxjs';
import {map, shareReplay, switchMap} from 'rxjs/operators';
import {ApiService} from 'src/modules/app/services/api.service';
import {UserGroup} from 'src/modules/user-group/models/user-group';
import {Vehicle} from 'src/modules/vehicle/models/vehicle';
import {Tariff} from '../models/tariff';

@Injectable({
  providedIn: 'root'
})
export class TariffService {
  currentTariff$: BehaviorSubject<Tariff> = new BehaviorSubject<Tariff>(null);

  constructor(
    private tableService: TableService,
    private apiService: ApiService,
    private isEmptyPipe: IsEmptyPipe
  ) {
  }

  tariffsLabelKeyFormat(providerId: string): Observable<SelectItem[]> {
    const params = new HttpParams().set('provider', providerId);
    return this.apiService.get<HydraSchema<Tariff>>('/api/tariffs', {params})
      .pipe(
        map(data => data['hydra:member'].map(tariff => ({
          label: this.isEmptyPipe.transform(tariff.name) as string,
          key: tariff['@id']
        }))),
        shareReplay(1));
  }

  getAllTariffsByPage(page?: number, filterParams?: HttpParams): Observable<HydraSchema<Tariff>> {
    let params: HttpParams;
    if (!filterParams) {
      params = new HttpParams()
        .set('groups[]', 'provider')
        .append('groups[]', 'vehicle')
        .append('groups[]', 'userGroup')
        .append('groups[]', 'bikeType')
        .append('groups[]', 'carType')
        .append('groups[]', 'cargoBikeType');
    } else {
      params = filterParams
        .set('groups[]', 'provider')
        .append('groups[]', 'vehicle')
        .append('groups[]', 'userGroup')
        .append('groups[]', 'bikeType')
        .append('groups[]', 'carType')
        .append('groups[]', 'cargoBikeType');
    }

    return this.tableService.getSingleTablePage('/api/tariffs', page, 10, params).pipe(
      switchMap((tariffs: HydraSchema<Tariff>) => {
        const tariffsWithAllData = tariffs['hydra:member'].map(tariffData =>
          combineLatest([
            this.getVehicles(tariffData['@id'].split('/').pop()),
            this.getUserGroups(tariffData['@id'].split('/').pop()),
          ]).pipe(map(([vehicles, userGroups]) => {
            tariffData.vehicles = vehicles['hydra:member'];
            tariffData.userGroups = userGroups['hydra:member'];
            return tariffData;
          })));
        return forkJoin(tariffsWithAllData).pipe(
          map(tariffswithData => {
            tariffs['hydra:member'] = tariffswithData.map(tariff => new Tariff(tariff));
            return tariffs;
          })
        );
      })
    );
  }

  addTariff(tariff: Partial<Tariff>) {
    return this.apiService.post('/api/tariffs', tariff);
  }

  fetchTariff(tariffId: string) {
    const params = new HttpParams()
      .set('groups[]', 'provider')
      .append('groups[]', 'vehicle')
      .append('groups[]', 'bikeType')
      .append('groups[]', 'carType')
      .append('groups[]', 'cargoBikeType')
      .append('groups[]', 'userGroup');

  return combineLatest([
      this.apiService.get<Tariff>(`/api/tariffs/${tariffId}`, {params}),
      this.getVehicles(tariffId),
      this.getUserGroups(tariffId),
    ]).pipe(map(([tariff, vehicles, userGroups]) => {
      tariff.userGroups = userGroups['hydra:member'];
      tariff.vehicles = vehicles['hydra:member'];
      return tariff;
    }));
  }

  getAllUsergroupOfProvider(providerId: string): Observable<HydraSchema<UserGroup>> {
    const params = new HttpParams()
      .set('provider', `${providerId}`)
      .append('groups[]', 'usergroup');
    return this.apiService.get(`/api/user_groups`, {params});
  }

  getAllVehiclesOfProivder(providerId: string) {
    const params = new HttpParams()
      .set('provider', `${providerId}`)
      .append('groups[]', 'biketype');
    return this.apiService.get('/api/vehicles', {params});
  }

  fetchVehiclesByTariffId(tariffId: string, page?: number): Observable<Vehicle> {
    const params = new HttpParams()
      .set('tariffs', tariffId)
      .append('groups[]', 'bikeType')
      .append('groups[]', 'cargoBikeType')
      .append('groups[]', 'carType')
      .append('groups[]', 'box');
    return this.tableService.getSingleTablePage('/api/vehicles', page, 10, params);
  }

  delelteTariffById(tariffId: string) {
    return this.apiService.delete(`/api/tariffs/${tariffId}`);
  }

  getAllProviders<T>(path: string): Observable<T> {
    const params = new HttpParams()
      .set('exists[deleted]', 'false')
      .set('pagination', 'false');
    return this.apiService.get<T>(path, {params});
  }

  getAllVehicles() {
    const params = new HttpParams()
      .append('groups[]', 'bikeType');
    return this.apiService.get('/api/vehicles', {params});
  }

  getVehicles(userId: string): Observable<HydraSchema<Vehicle>> {
    const vehicleParams = new HttpParams()
      .set('groups[]', 'bikeType')
      .append('groups[]', 'cargoBikeType')
      .append('groups[]', 'carType')
      .append('groups[]', 'box');
    return this.apiService.get<HydraSchema<Vehicle>>(`/api/tariffs/${userId}/vehicles`, {params: vehicleParams});
  }

  getUserGroups(userId: string): Observable<HydraSchema<UserGroup>> {
    const userGroupParams = new HttpParams()
      .set('groups[]', 'provider');
    return this.apiService.get<HydraSchema<UserGroup>>(`/api/tariffs/${userId}/user_groups`, {params: userGroupParams});
  }

  updateTariff(tariffId: string, newTariffData: Partial<Tariff>) {
    return this.apiService.patch<Tariff>(`/api/tariffs/${tariffId}`, newTariffData);
  }
}
