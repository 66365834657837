import {Provider} from 'src/modules/provider/models/provider';
import {Bike, Car, CargoBike, Scooter} from 'src/modules/vehicle/models/vehicle';

export class Lock {
    '@context': string;
    '@id': string;
    '@type': string;
    name: string;
    priority: number;
    lockId: string;
    provider: Provider | string;
    vehicle: Bike | Car | Scooter | CargoBike | string;
    id: string;

    constructor(lock: Lock) {
        this['@id'] = lock['@id'];
        this['@context'] = lock['@context'];
        this['@type'] = lock['@type'];
        this.name = lock.name;
        this.priority = lock.priority;
        this.lockId = lock.lockId;
        this.provider = lock.provider;
        this.vehicle = lock.vehicle;
        this.id = this['@id']?.split('/').pop();
    }
}

