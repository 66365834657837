import {BikeInterface, CargoBikeInterface, CarInterface} from '../../vehicle/models/vehicle';
import {BookingState} from '@ui/enums/booking-state.enum';
import {Payment} from './payment';
import {Tariff} from '../../tariff/models/tariff';
import {Provider} from '../../provider/models/provider';
import {Customer} from '../../user/models/customer';
import {UserGroup} from 'src/modules/user-group/models/user-group';
import {Hub} from 'src/modules/hub/models/hub';

export class Booking {
  '@id': string;
  '@type': 'Booking';
  recordedEndTime: Date;
  provider: string | Provider;
  payment: Payment;
  tariffs: Tariff[];
  orderedStartTime: Date;
  orderedEndTime: Date;
  damageReports: [];
  customer: string | Customer;
  vehicle: CarInterface | BikeInterface | CargoBikeInterface;
  cancelled: Date;
  returned: boolean;
  createdAt: Date;
  state: BookingState;
  id: string;
  userGroup: UserGroup;
  box: Hub;

  constructor(booking: Booking) {
    this['@id'] = booking['@id'];
    this['@type'] = booking['@type'];
    this.recordedEndTime = booking.recordedEndTime;
    this.provider = booking.provider;
    this.payment = booking.payment;
    this.tariffs = booking.tariffs;
    this.orderedStartTime = booking.orderedStartTime;
    this.orderedEndTime = booking.orderedEndTime;
    this.damageReports = booking.damageReports;
    this.customer = booking.customer;
    this.vehicle = booking.vehicle;
    this.cancelled = booking.cancelled;
    this.returned = booking.returned;
    this.createdAt = booking.createdAt;
    this.state = booking.state;
    this.id = this['@id']?.split('/').pop();
    this.userGroup = booking.userGroup;
    this.box = booking.box;
  }
}
